/* eslint-disable camelcase, no-else-return, consistent-return */

import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete';

import styles from './style.module.scss';
// import CustomInput from "../../../components/Elements/TextInput/CustomInput";
import customStyle from '../../../components/Elements/TextInput/customInput.module.scss';
import { LettersAndNumbersOnly } from '../../../lib/common';

const displayNotification = (description) => {
  notification.warning({
    message: 'Account',
    description,
  });
};

const AddressModal = ({ handleClose, handleSave, defaultValue }) => {
  const [addressValue, setAddressValue] = useState('');
  const [label, setLabel] = useState('');
  const [locationType, setLocationType] = useState(3);
  const [hasError, setHasError] = useState(false);

  const handleChangeValue = async (value, placeId = null) => {
    let add = value;

    if (placeId) {
      const completeLoc = await geocodeByPlaceId(placeId);
      const zip_code = completeLoc[0].address_components.find((addr) => addr.types[0] === 'postal_code')?.short_name;
      if (zip_code) {
        add = `${value}, ${zip_code}`;
      }
    }

    setAddressValue(add);
  };
  const save = () => {
    if (hasError) {
      return displayNotification('Label name should only contain letters and numbers.');
    }
    return handleSave(addressValue, label, parseInt(locationType, 10));
  };

  useEffect(() => {
    if (label?.trim().length > 0) {
      if (!LettersAndNumbersOnly(label?.trim())) {
        setHasError(true);
        return displayNotification('Label name should only contain letters and numbers.');
      } else if (hasError) {
        setHasError(false);
      }
    }
    // eslint-disable-next-line
  }, [label]);

  useEffect(() => {
    if (defaultValue) {
      setAddressValue(defaultValue.addressValue.trim());
      setLabel(defaultValue.label);
      setLocationType(defaultValue.locationType);
    }
  }, [defaultValue]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div>Address</div>
          <div>To create a new account profile to assist the payment, billing and booking management.</div>
        </div>

        <div className={styles['content-data-box']}>
          <div className={styles['inputs-container']}>
            <div className={customStyle.input}>
              <div className={customStyle.label}>Label (i.e., Facility Name At The Address) </div>
              <input
                defaultValue={label}
                className={customStyle['text-input']}
                type="text"
                onKeyUp={(e) => setLabel(e.target.value)}
              />
            </div>
          </div>
          <div className={styles['inputs-container']}>
            <div className={customStyle.input}>
              <div className={customStyle.label}>
                Address <span className={customStyle.required}>*</span>
              </div>
              <PlacesAutocomplete value={addressValue} onChange={handleChangeValue} onSelect={handleChangeValue}>
                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                  <div
                    style={{
                      position: 'relative',
                      zIndex: 9999,
                      width: '100%',
                    }}
                  >
                    <input
                      {...getInputProps({
                        className: `normal form-control ${styles['select-input']}`,
                      })}
                    />
                    <div
                      className=""
                      style={{
                        position: 'absolute',
                        zIndex: 99999,
                        width: '100%',
                        fontSize: '14px',
                      }}
                    >
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? {
                              backgroundColor: '#fafafa',
                              cursor: 'pointer',
                              padding: 5,
                            }
                          : {
                              backgroundColor: 'rgb(236 232 232)',
                              cursor: 'pointer',
                              padding: 5,
                            };
                        return (
                          <div
                            id="address"
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                            key={suggestion.description}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </div>
          </div>
          <div className={styles['inputs-container']}>
            <div className={customStyle.input}>
              <div className={customStyle.label}>Added To Booking Address List?</div>
              <select
                className="form-control gray"
                style={{ width: '100%' }}
                name="fieldName"
                onChange={(e) => {
                  setLocationType(e.target.value);
                }}
                onSelect={(e) => {
                  setLocationType(e.target.value);
                }}
                value={locationType}
              >
                <option value={3}>Yes, you can access it while creating a new booking.</option>
                <option value={2}>No</option>
              </select>
            </div>
          </div>
        </div>

        <div className={styles.button__container}>
          <button type="button" className={styles.add__more} style={{ float: 'unset' }} onClick={handleClose}>
            Cancel
          </button>
          <button type="button" className={styles.close} onClick={save}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddressModal;
