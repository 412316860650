/* eslint-disable jsx-a11y/click-events-have-key-events, no-unused-expressions, jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import '../new-scheduler/booking-details.css';
import BookingDetails from '../new-scheduler/booking-details';
import EditSharedTripFlow from './edit-index';

const ViewSharedTrips = ({
  booking,
  selectedDate,
  close,
  carName,
  removeBooking,
  getAvailableBookingTime,
  showRoutes,
  showTrackingLink,
  unDispatchedBooking,
  setSpinner,
  unsetSpinner,
  programId,
  updateTimeAfterShareTripsUpdate,
}) => {
  const [bookingList, setBookingList] = useState([]);
  const [viewedBooking, setViewedBooking] = useState();
  const [isEdit, setIsEdit] = useState(false);

  const handleOverLayClick = (e) => {
    if (e.target.className === 'booking__details--overlay') {
      close();
    }
  };

  const parseTime = (time) => {
    const [hourMinute, period] = time.split(' '); // Split into "6:28" and "AM/PM"
    const [hours, minutes] = hourMinute.split(':').map(Number); // Split "6:28" into hours and minutes

    let hour24 = hours % 12; // Convert to 12-hour format
    if (period === 'PM') hour24 += 12; // Convert PM to 24-hour format

    return hour24 * 60 + minutes; // Convert to total minutes for easy comparison
  };

  useEffect(() => {
    const tempBookings = [];

    booking?.bookings.forEach((bkng) => {
      const pickupObject = {
        ...bkng,
        time: bkng.scheduled_pickup_time,
        type: 'Pickup',
      };

      const dropoffObject = {
        ...bkng,
        time: bkng.scheduled_dropoff_time,
        type: 'Drop-off',
      };
      tempBookings.push(pickupObject, dropoffObject);
    });

    const sortedData = tempBookings.sort((a, b) => {
      return parseTime(a.time) - parseTime(b.time);
    });

    setBookingList(sortedData);
  }, [booking]);

  return (
    <>
      {isEdit ? (
        <EditSharedTripFlow
          bookings={bookingList}
          selectedDate={selectedDate}
          close={() => setIsEdit(false)}
          backToDispatchBoard={close}
          unDispatchedBooking={unDispatchedBooking}
          setSpinner={setSpinner}
          unsetSpinner={unsetSpinner}
          programId={programId}
          shuttleAnchorLocation={booking?.shuttle_anchor_location}
          shuttleId={booking.shuttleId}
          tripId={booking?.trip?.trip_id}
          updateTimeAfterShareTripsUpdate={updateTimeAfterShareTripsUpdate}
        />
      ) : (
        <>
          {viewedBooking ? (
            <BookingDetails
              showRoutes={showRoutes}
              getAvailableBookingTime={getAvailableBookingTime}
              removeBooking={removeBooking}
              carName={carName}
              booking={viewedBooking}
              selectedDate={selectedDate}
              close={() => setViewedBooking(null)}
              showTrackingLink={showTrackingLink}
              isFromSharedTrips
            />
          ) : (
            <div className="booking__details--overlay" onClick={handleOverLayClick} role="button" tabIndex="0">
              <div className="booking__details--modals ">
                <>
                  <div className="booking__details_view">
                    <div className="booking_details_container">
                      <div className="booking__details--header">
                        {booking.type !== 'autoScheduling' && (
                          <>
                            <span
                              onClick={() => {}}
                              onKeyPress={() => {}}
                              role="button"
                              tabIndex="0"
                              className="btnBorderRight"
                            >
                              <IconButton onClick={() => setIsEdit(true)}>
                                <i className="fa fa-edit" />
                              </IconButton>
                            </span>
                          </>
                        )}
                        <span onClick={close} onKeyPress={close} role="button" tabIndex="0">
                          <IconButton>
                            <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                            </svg>
                          </IconButton>
                        </span>
                      </div>

                      <div className="booking__details--content mb-4">
                        <div className="booking__details__name">Shared Trip</div>
                        <div className="booking__details__fee mb-3">Pickup and drop-off order</div>
                        {bookingList.map((bkng) => {
                          return (
                            <div className="booking__details__fee">
                              <span className="mr-2 viewBookingIcon" onClick={() => setViewedBooking(bkng)}>
                                <i className="fa fa-eye" />
                              </span>{' '}
                              {bkng.time} {bkng.type}, {`${bkng.passenger_firstname} ${bkng.passenger_lastname}`}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ViewSharedTrips;
