import React from 'react';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';

import DuetModal2, { DuetIconContainer, DuetLeftRightButtons } from '../../../components/Modals/modal2';

import styles from './styles.module.scss';
import modalStyles from '../../../components/Modals/modal2.module.scss';

const CancelUpdatePickupTime = ({ show, yes, no }) => {
  return (
    <>
      <DuetModal2 show={show}>
        <DuetIconContainer>
          <img
            style={{ width: '57px', height: '57px' }}
            src={`${process.env.PUBLIC_URL}/resources/images/info-circle-blue.svg`}
            alt=""
          />
        </DuetIconContainer>
        <div className={`${styles['modal-subtitle']} m-t-30`}>
          All changes made to Step 2 on this window will be discarded after closing this window. How ever the change of
          Step 1 made in the prevoius window will remain.
          <br />
          <br />
          Close the window?
        </div>
        <div className="m-t-40">
          <DuetLeftRightButtons>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Button
                variant="secondary"
                type="button"
                className={classNames(modalStyles.button, 'w-80', 'm-l-12')}
                disabled={false}
                onClick={no}
              >
                No
              </Button>
              <Button
                variant="primary"
                type="button"
                className={classNames(modalStyles.button, 'w-80', 'm-l-12')}
                disabled={false}
                onClick={yes}
              >
                Yes
              </Button>
            </div>
          </DuetLeftRightButtons>
        </div>
      </DuetModal2>
    </>
  );
};

export default CancelUpdatePickupTime;
