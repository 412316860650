import React from 'react';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';

import DuetModal2, { DuetIconContainer, DuetLeftRightButtons } from '../../../components/Modals/modal2';

import styles from './styles.module.scss';
import modalStyles from '../../../components/Modals/modal2.module.scss';

const RemoveConfirmation = ({ show, close, remove }) => {
  return (
    <>
      <DuetModal2 show={show}>
        <DuetIconContainer>
          <img
            style={{ width: '57px', height: '57px' }}
            src={`${process.env.PUBLIC_URL}/resources/images/info-circle-blue.svg`}
            alt=""
          />
        </DuetIconContainer>
        <div className={`${styles['modal-subtitle']} m-t-30`}>Do you want to remove the selected booking?</div>
        <div className={`${styles['modal-subtitle']} m-t-10 m-b-20`}>
          <strong>Note:</strong> only booking with the &quot;Dispatched&quot; can be removed.
        </div>
        <div className="m-t-40">
          <DuetLeftRightButtons>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Button
                variant="secondary"
                type="button"
                className={classNames(modalStyles.button, 'w-80', 'm-l-12')}
                disabled={false}
                onClick={close}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="button"
                className={classNames(modalStyles.button, 'w-80', 'm-l-12')}
                disabled={false}
                onClick={remove}
              >
                Ok
              </Button>
            </div>
          </DuetLeftRightButtons>
        </div>
      </DuetModal2>
    </>
  );
};

export default RemoveConfirmation;
