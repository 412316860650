/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable default-case, no-useless-return,react/no-array-index-key,consistent-return, react-hooks/exhaustive-deps, prefer-template, jsx-a11y/tabindex-no-positive, no-restricted-globals, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, camelcase  */
/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';

import { notification } from 'antd';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { Modal, ModalBody } from 'react-bootstrap';

import { saveFleetScheduleData, saveLunchBreak, saveTimeBlock } from '../../../services/drivers';

import './driver-schedule.css';
import TimeBlockModal from './timeblock';
import { convertTime, dateRangeValid, setTimeWithDate } from './constant';
import LunchBreakModal from './LunchBreak';
import TripMenu from './trip-menu';
import DriverAction from './driver-actions';
import { formatAMPM, setDBTime } from '../../../lib/common';
import TimeInput from '../../../components/Elements/TextInput/TimeInput';
import MultiLoad from '../multitrips';

dayjs.extend(duration);

function convertNumToTime(number) {
  // Check sign of given number
  let sign = number >= 0 ? 1 : -1;

  // Set positive value of number of sign negative
  number *= sign;

  // Separate the int from the decimal part
  const hour = Math.floor(number);
  let decpart = number - hour;

  const min = 1 / 60;
  // Round to nearest minute
  decpart = min * Math.round(decpart / min);

  let minute = Math.floor(decpart * 60) + '';

  // Add padding if need
  if (minute.length < 2) {
    minute = '0' + minute;
  }

  // Add Sign in final result
  sign = sign === 1 ? '' : '-';

  // Concate hours and minutes
  // eslint-disable-next-line no-undef
  const time = sign + (hour + minute / 60).toFixed(2);

  // eslint-disable-next-line no-undef
  return time;
}

const defaultTimeValue = {
  keyIndex: -1,
  period: '',
  values: {
    startTime: '',
    endTime: '',
  },
  driverSched: null,
};

/**
 * This Function handles the logic for Drivers schedule
 * Drag and Drop features
 * @returns
 */
const DriversWorkingHours = ({
  driverSchedule,
  converterToHeight,
  hourHeightInPixel,
  driver,
  trips,
  scheduleId,
  allDriverList,
  disPatchSelectedDate,
  programId,
  isTeam,
  setSpinner,
  unsetSpinner,
  selectedDate: dispatchDate,
  combineAddedVehicle,
  vehicleTrips,
  // refreshed,
  xyPositionProps,
  showTripMenu, // convert to react-hook in the future, this is a patch for now
  isNewScheduler,
  bookings,
  updateDriverInAllDriversArray,
  updateBookingAfterSharedRide,
}) => {
  const thisDiv = useRef();

  const [isMinimized, setIsMinimized] = useState(false);
  const [schedule, setSchedule] = useState();
  const [teamSchedule, setTeamSchedule] = useState();
  const [containerSettings, setContainerSettings] = useState({
    top: 0,
    height: 70,
  });
  const [driversSchedule, setDriversSchedule] = useState([]);
  const [feeTotal, setFeeTotal] = useState(0.0);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showTimeBlockModal, setShowTimeBlockModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [allDriver, setAllDriver] = useState(allDriverList);
  const [showRightClickMenu, setShowRightClickMenu] = useState(false);
  const [xyPosition, setXYPosition] = useState({ x: 0, y: 0 });
  const [hours, setHours] = useState(); // total hours of team
  const [timeBlockStart, setTimeBlockStart] = useState();
  const [timeBlockEnd, setTimeBlockEnd] = useState();
  const [timeBlockNotes, setTimeBlockNotes] = useState('');

  const [lunchBreakStart, setLunchBreakStart] = useState();
  const [lunchBreakEnd, setLunchBreakEnd] = useState();
  const [showLunchBreakModal, setShowLunchBreakModal] = useState(false);
  const [showDriverAction, setShowDriverAction] = useState(false);
  const [showMultiLoad, setShowMultiLoad] = useState(false);
  const [checkedBookings, setCheckedBookings] = useState([]);
  // const [enterTime, setEnterTime] = useState(defaultTimeValue); // no longer used

  useEffect(() => {
    if (showTimeBlockModal) {
      setTimeBlockStart(convertTime('1:00'));
      setTimeBlockEnd(convertTime('18:00'));
      setTimeBlockNotes('');
    }
  }, [showTimeBlockModal]);

  useEffect(() => {
    if (bookings.length) {
      setCheckedBookings(bookings.filter((elem) => elem.checked && !elem.dispatched));
    }
  }, [bookings]);

  useEffect(() => {
    if (showLunchBreakModal) {
      setLunchBreakStart(convertTime('1:00'));
      setLunchBreakEnd(convertTime('18:00'));
    }
  }, [showLunchBreakModal]);

  useEffect(() => {
    if (isTeam) {
      computeTeamHeight(driverSchedule);
    }
  }, [isTeam]);

  useEffect(() => {
    setScheduleData(driverSchedule);
  }, [driverSchedule]);

  useEffect(() => {
    if (trips) {
      try {
        const total = trips.reduce((accumulator, object) => {
          if (object.bookings[0]) {
            return accumulator + object.bookings[0].ride_fee;
          }

          return accumulator;
        }, 0);

        setFeeTotal((total / 100).toFixed(2));
      } catch (e) {
        console.log(e);
      }
    }
  }, [trips]);

  useEffect(() => {
    if (isUpdating) {
      setSpinner();
    } else {
      unsetSpinner();
    }
  }, [isUpdating]);

  useEffect(() => {
    if (!isTeam && schedule && schedule.schedule_data[0] && schedule.schedule_data[0].time_data) {
      const sTime = getStartTime(schedule.schedule_data[0].time_data);

      // compute total hours if driver has multiple schedule
      const timeIn = sTime[0].start_time;
      const timeOut = sTime[sTime.length - 1].end_time;

      const start = new Date(`01/20/1988 ${timeIn}`);
      const end = new Date(`01/20/1988 ${timeOut}`);

      const totalD = dayjs.duration(dayjs(end).diff(dayjs(start)));

      const totalHours = totalD.hours() + totalD.minutes() / 60;

      computeTopAndHeight(sTime, {
        schedule_data: [
          {
            program_total_hours: totalHours,
          },
        ],
      });
      setDriversSchedule(sTime);
    }
  }, [schedule]);

  useEffect(() => {
    document.addEventListener('drop', DropEvent);
    document.addEventListener('dragleave', (e) => {
      if (e.target.className === 'item') {
        DropEvent();
      }
    });
    return () => {
      document.removeEventListener('drop', DropEvent);
    };
  }, []);

  const setScheduleData = (data) => {
    if (data.length > 0 && !isTeam) {
      setSchedule(data[0]);
    }

    if (isTeam) {
      setSchedule(data);
    }
  };

  const handleTeamCancel = () => {
    setShowEditModal(false);
    // refreshed();
  };

  const computeTeamHeight = (driversSchedules = []) => {
    // compute team schedule
    let timeIn = '';
    let timeOut = '';
    const driversStartEndTime = [];

    driversSchedules.map((sched, i) => {
      driversStartEndTime[i] = {
        start: '',
        end: '',
      };

      if (sched.schedule_data.length && sched.schedule_data[0].time_data) {
        const sortedSched = sched.schedule_data[0].time_data.sort((a, b) => {
          return dayjs(`01/20/1988 ${a.start_time}`) - dayjs(`01/20/1988 ${b.start_time}`);
        });

        const start = composeTime(sortedSched[0].start_time);
        const end = composeTime(sortedSched[sortedSched.length - 1].end_time);

        if (timeIn === '') {
          timeIn = start;
        }
        if (timeOut === '') {
          timeOut = end;
        }

        // get the latest timeIn
        if (dayjs(`01/20/1988 ${timeIn}`) < dayjs(`01/20/1988 ${start}`)) {
          timeIn = start;
        }
        // get the earliest time out
        if (dayjs(`01/20/1988 ${timeOut}`) > dayjs(`01/20/1988 ${end}`)) {
          timeOut = end;
        }

        driversStartEndTime[i] = {
          start,
          end,
        };

        // update with sorted data
        sched.schedule_data[0].time_data = [...sortedSched];
      }
      return sched;
    });

    // update with sorted
    setDriversSchedule([...driverSchedule]);

    // check if drivers got time intersection if not then no need tocompute the start and end time
    driversStartEndTime.sort((a, b) => {
      return dayjs(`01/20/1988 ${a.start}`) - dayjs(`01/20/1988 ${b.start}`);
    });

    let intersect = false;
    // driver if all got same shift first check

    if (
      driversStartEndTime.length > 0 &&
      driversStartEndTime.every(
        (sched) => JSON.stringify(sched) === JSON.stringify(driversStartEndTime[0]) && sched?.start !== ''
      )
    ) {
      intersect = true;
      timeIn = composeTime(driversStartEndTime[0].start.replace(' AM', '').replace('PM', ''));
      timeOut = composeTime(driversStartEndTime[0].end.replace(' AM', '').replace('PM', ''));
    }

    // second check, if its falls, meaning shifts are all different. lets loop through the shift array and compare each other to see if there are intersection.
    // if there are no interection for atleast 2 drivers, set intersection to false
    try {
      if (!intersect) {
        driversStartEndTime.forEach((a) => {
          driversStartEndTime.forEach((b) => {
            if (JSON.stringify(a) !== JSON.stringify(b)) {
              if (dayjs(`01/20/1988 ${a.end}`) >= dayjs(`01/20/1988 ${b.start}`)) {
                intersect = true;
              } else {
                intersect = false;
                setContainerSettings({
                  top: 0,
                  height: 70,
                });
                setHours(0);
                throw new Error('no need to check');
              }
            }
          });
        });
      }
    } catch (e) {
      console.error(e);
    }

    if (intersect) {
      timeIn = timeIn.replace(' AM', '').replace('PM', '');
      timeOut = timeOut.replace(' AM', '').replace('PM', '');
      setTeamSchedule({ start: timeIn, end: timeOut });

      const start = new Date(`01/20/1988 ${timeIn}`);
      const end = new Date(`01/20/1988 ${timeOut}`);

      const totalD = dayjs.duration(dayjs(end).diff(dayjs(start)));

      const totalHours = (totalD.hours() + totalD.minutes() / 60).toFixed(2, 10);

      setHours(totalHours);

      if (totalHours === 0) {
        setContainerSettings({
          top: 0,
          height: 70,
        });
      }

      // compute the container height based on the timeIn and timeOut Value

      const sTime = getStartTime([
        {
          start_time: timeIn.replace(' AM', '').replace('PM', ''),
          end_time: timeOut.replace(' AM', '').replace('PM', ''),
          duration: totalHours,
        },
      ]);

      computeTopAndHeight(sTime, {
        schedule_data: [
          {
            program_total_hours: totalHours,
          },
        ],
      });
    }
  };

  const displayNotification = (description) => {
    notification.warning({
      message: 'Alert',
      description,
    });
  };

  const getStartTime = (timeData) => {
    const sTime = timeData.sort((a, b) => {
      const aStartTime = Number(a.start_time.split(':')[0]);
      const bStartTime = Number(b.start_time.split(':')[0]);

      let comparison = 0;
      if (aStartTime < bStartTime) {
        comparison = -1;
      } else if (aStartTime > bStartTime) {
        comparison = 1;
      }
      return comparison;
    });

    return sTime;
  };

  /**
   * compute height and top of the container
   */
  const computeTopAndHeight = (sTime, sched) => {
    const startTime = {
      hour: Number(sTime[0].start_time.split(':')[0]),
      min: Number(sTime[0].start_time.split(':')[1]) || 0,
    };

    const top = startTime.hour * hourHeightInPixel + startTime.min * converterToHeight;

    if (sched && sched.schedule_data && sched.schedule_data[0]) {
      setContainerSettings({
        top,
        height:
          sched.schedule_data[0].program_total_hours > 0
            ? Number(sched.schedule_data[0].program_total_hours * hourHeightInPixel)
            : 50,
      });
    }

    return sTime;
  };

  /**
   * show the driver schedule after dropping event
   */
  const DropEvent = () => {
    if (thisDiv.current) {
      thisDiv.current.style['z-index'] = 9;
    }
  };

  /**
   * this will hide the drivers schedule on drag over event
   * @param {*} e event (the driver schedule) where the element/booking was dragged over
   */
  const handleOnDragOver = (e) => {
    e.preventDefault();
    if (e.target.id === 'driver-schedule-div') {
      e.target.style['z-index'] = '-1';
    }
  };

  /**
   * this will show the drivers schedule on drag leave event
   * @param {*} e event (the driver schedule) where the element/booking drag event left
   */
  const handleDragLeave = (e) => {
    e.preventDefault();

    if (e.target.id === 'driver-schedule-div') {
      e.target.style.opacity = '1';
    }
  };

  const handleMinimized = () => {
    setIsMinimized(!isMinimized);
  };

  const handleClick = (e) => {
    if (e.target.id === 'driver-schedule-div') {
      e.target.classList.add('driver-working-hours-top');
    }
  };

  const handleMouseLeave = (e) => {
    if (e.target.id === 'driver-schedule-div') {
      e.target.classList.remove('driver-working-hours-top');
    }
  };

  const handleShiftSchedule = () => {
    setShowEditModal(!showEditModal);
  };

  const composeTime = (time) => {
    return dayjs(`${dayjs().format('MMMM DD, YYYY')} ${time}:00`).format('HH:mm');
  };

  const composeTime12Format = (time) => {
    return dayjs(`${dayjs().format('MMMM DD, YYYY')} ${time}`).format('hh:mm A');
  };

  // updating driver shift under the team
  const handleDriverDateChange = (date, index, period, driverData) => {
    let driverObjIndex = schedule.findIndex(({ driver_id }) => driverData.driver_id === driver_id);

    const otherTime = dayjs(date);

    // if driver doesnt exist add it to array
    if (driverObjIndex === -1) {
      schedule.push({
        driver_id: driverData.driver_id,
        driver_total_hours: 0,
        firstname: driverData.driver_firstname,
        lastname: driverData.driver_lastname,
        schedule_data: [],
      });

      setSchedule([...schedule]);
    }

    driverObjIndex = schedule.findIndex(({ driver_id }) => driverData.driver_id === driver_id);

    if (schedule[driverObjIndex].schedule_data.length === 0) {
      const timeData = {
        start_time: dayjs(otherTime.subtract(15, 'minutes')).format('HH:mm'),
        start_time_val: 0,
        end_time: dayjs(otherTime.add(15, 'minutes')).format('HH:mm'),
        end_time_val: 0,
        duration: 0,
      };
      const scheduleData = {
        program_id: programId,
        program_timezone: dayjs.tz.guess(),
        program_total_hours: 0,
        time_data: [timeData],
      };

      schedule[driverObjIndex].schedule_data.push(scheduleData);
      setSchedule([...schedule]);
    }

    const updatedTime = `${('0' + date.getHours()).substr(-2)}:${('0' + date.getMinutes()).substr(-2)}`;

    const time = convertEpochTime(
      schedule[driverObjIndex].schedule_data[0].time_data[index].start_time_val,
      updatedTime
    );

    if (period === 'start') {
      const endTime = convertEpochTime(
        schedule[driverObjIndex].schedule_data[0].time_data[index].start_time_val,
        schedule[driverObjIndex].schedule_data[0].time_data[index].end_time
      );

      if (!validateDate(time, endTime)) {
        return;
      }

      schedule[driverObjIndex].schedule_data[0].time_data[index].start_time = updatedTime;
    } else {
      const startTime = convertEpochTime(
        schedule[driverObjIndex].schedule_data[0].time_data[index].start_time_val,
        schedule[driverObjIndex].schedule_data[0].time_data[index].start_time
      );

      if (!validateDate(startTime, time)) {
        return;
      }
      schedule[driverObjIndex].schedule_data[0].time_data[index].end_time = updatedTime;
    }

    // get the start time from the first array
    let startTime = new Date(
      dayjs(
        convertEpochTime(
          schedule[driverObjIndex].schedule_data[0].time_data[index].start_time_val,
          schedule[driverObjIndex].schedule_data[0].time_data[index].start_time
        )
      )
    ).getTime();
    let endTime = new Date(
      dayjs(
        convertEpochTime(
          schedule[driverObjIndex].schedule_data[0].time_data[index].end_time_val,
          schedule[driverObjIndex].schedule_data[0].time_data[index].end_time
        )
      )
    ).getTime();

    startTime = dayjs(Number(startTime));
    endTime = dayjs(Number(endTime));

    const totalDuration = endTime.diff(startTime, 'hour', true);
    schedule[driverObjIndex].schedule_data[0].program_total_hours = totalDuration;
    schedule[driverObjIndex].driver_total_hours = totalDuration;
    // schedule[driverObjIndex].schedule_data[0].time_data = [...schedule[driverObjIndex].schedule_data[0].time_data.map((data) => {
    //   delete data.program_total_hours;

    //   return data;
    // })]
    schedule[driverObjIndex].schedule_data[0].time_data[index].duration = totalDuration;
    setSchedule([...schedule]);

    // update list of driver with the schedule
    if (totalDuration !== 0) {
      const indexDriver = allDriver.findIndex(({ driver_id }) => driver_id === schedule[driverObjIndex].driver_id);

      if (indexDriver > -1) {
        allDriver[indexDriver] = schedule[driverObjIndex];

        setAllDriver([
          ...allDriver.map((driverDataMap) => {
            return { ...driverDataMap };
          }),
        ]);
      } else {
        allDriver.push(schedule[driverObjIndex]);
      }
    }

    setTimeout(() => {
      computeTeamHeight(schedule);
    }, []);
  };

  // updating individual driver shift
  const handleDateChange = (date, index, period) => {
    // this is a patch if schedule_data is empty
    if (driversSchedule.length === 0) {
      const otherTime = dayjs(date);
      const timeData = {
        start_time: dayjs(otherTime.subtract(15, 'minutes')).format('HH:mm'),
        start_time_val: 0,
        end_time: dayjs(otherTime.add(15, 'minutes')).format('HH:mm'),
        end_time_val: 0,
        duration: 0,
      };
      driversSchedule.push(timeData);

      const driverToUpdate = driver;

      const key = allDriver.findIndex((d) => d.driver_id === driverToUpdate.driver_id);

      const scheduleData = {
        program_id: programId,
        program_timezone: dayjs.tz.guess(),
        program_total_hours: 0,
        time_data: [timeData],
      };

      if (key > -1) {
        allDriver[key].schedule_data.push(scheduleData);
        setAllDriver([
          ...allDriver.map((driverDataMap) => {
            return { ...driverDataMap };
          }),
        ]);
      } else {
        // add driver in the list
        allDriver.push({
          driver_id: driverToUpdate.driver_id,
          driver_total_hours: 0,
          firstname: driverToUpdate.driver_firstname,
          lastname: driverToUpdate.driver_lastname,
          schedule_data: [scheduleData],
        });

        setAllDriver([
          ...allDriver.map((driverDataMap) => {
            return { ...driverDataMap };
          }),
        ]);
      }
    }

    try {
      /* get The date selected */

      const updatedTime = `${('0' + date.getHours()).substr(-2)}:${('0' + date.getMinutes()).substr(-2)}`;

      const time = convertEpochTime(driversSchedule[index].start_time_val, updatedTime);

      /* checker if time shift is valid if multiple shift */

      // if (index - 1 >= 0) {
      //   const endTime = convertEpochTime(
      //     driversSchedule[index].start_time_val,
      //     driversSchedule[index - 1].end_time
      //   );

      //   if (!validateDate(time, endTime)) {
      //     return;
      //   }
      // }

      /* end of check */

      if (period === 'start') {
        const endTime = convertEpochTime(driversSchedule[index].start_time_val, driversSchedule[index].end_time);

        if (!validateDate(time, endTime)) {
          return;
        }

        driversSchedule[index].start_time = updatedTime;
        driversSchedule[index].start_time_val = new Date(dayjs(time)).getTime() / 1000;
      } else {
        const startTime = convertEpochTime(driversSchedule[index].start_time_val, driversSchedule[index].start_time);

        if (!validateDate(startTime, time)) {
          return;
        }

        driversSchedule[index].end_time = updatedTime;
        driversSchedule[index].end_time_val = new Date(dayjs(time)).getTime() / 1000;
      }

      const startTimeObj = dayjs(Number(driversSchedule[index].start_time_val * 1000));

      const endTimeObj = dayjs(Number(driversSchedule[index].end_time_val * 1000));

      const dateduration = endTimeObj.diff(startTimeObj, 'hour', true);
      driversSchedule[index].duration = dateduration;

      setDriversSchedule([...driversSchedule]);
    } catch (e) {
      console.error(e);
    }
  };

  const validateDate = (start, end) => {
    if (!dateRangeValid(start, end)) {
      displayNotification('Please input a valid time range.');
      return false;
    }

    return true;
  };

  const convertEpochTime = (timeVal, updatedTime) => {
    const selectedDate = dayjs.unix(timeVal).format('MM/DD/YYYY');

    return `${selectedDate} ${updatedTime}`;
  };

  const updateTeamDriverSchedule = async () => {
    try {
      setSpinner();

      setIsUpdating(true);
      const newDriverList = [
        ...allDriver.map((data) => {
          return { ...data };
        }),
      ];
      const params = {
        timezone: dayjs.tz.guess(),
        schedule_id: scheduleId,
        driver_schedule_list: newDriverList,
        date: `Today, ${dayjs(disPatchSelectedDate).format('LL')}`,
      };
      updateDriverInAllDriversArray(newDriverList);
      const response = await saveFleetScheduleData(params);

      computeTeamHeight(schedule);
      displayNotification(response.message);
    } catch (e) {
      console.error(e);
    }
    setShowEditModal(false);
    setIsUpdating(false);
    unsetSpinner();
  };

  const handleUpdateDriverSchedule = async () => {
    if (!driversSchedule.length) {
      driversSchedule.push({
        start_time: '01:00',
        start_time_val: -25200,
        end_time: '18:00',
        end_time_val: 0,
        duration: 7,
      });
    }

    setIsUpdating(true);
    setShowEditModal(false);
    try {
      const driverToUpdate = driver;

      let index = allDriver.findIndex((d) => d.driver_id === driverToUpdate.driver_id);
      if (index === -1) {
        const createDriverObject = {
          ...driverToUpdate,
          firstname: driverToUpdate.driver_firstname,
          lastname: driverToUpdate.driver_lastname,
          schedule_data: [],
        };

        allDriver.push(createDriverObject);
        index = allDriver.length - 1;
        setSchedule(createDriverObject);
      }
      if (allDriver[index] && allDriver[index].schedule_data && allDriver[index].schedule_data[0]) {
        allDriver[index].schedule_data[0].time_data = [...driversSchedule];
      } else {
        allDriver[index].schedule_data = [];
        allDriver[index].schedule_data.push({
          program_id: programId,
          program_timezone: dayjs.tz.guess(),
          program_total_hours: 0,
          time_data: [...driversSchedule],
        });
      }

      let totalDuration = driversSchedule[0].duration || 0;

      /* compute total duration if multiple shift */
      if (driversSchedule.length > 0) {
        // get the start time from the first array
        let startTime = new Date(
          dayjs(convertEpochTime(driversSchedule[0].start_time_val, driversSchedule[0].start_time))
        ).getTime();
        let endTime = new Date(
          dayjs(
            convertEpochTime(driversSchedule[0].start_time_val, driversSchedule[driversSchedule.length - 1].end_time)
          )
        ).getTime();

        startTime = dayjs(Number(startTime));
        endTime = dayjs(Number(endTime));

        totalDuration = endTime.diff(startTime, 'hour', true);
      }

      allDriver[index].schedule_data[0].program_total_hours = totalDuration;
      allDriver[index].driver_total_hours = totalDuration;
      const newDriverList = [
        ...allDriver.map((data) => {
          return { ...data };
        }),
      ];
      const params = {
        timezone: dayjs.tz.guess(),
        schedule_id: scheduleId,
        driver_schedule_list: [...newDriverList],
        date: `Today, ${dayjs(disPatchSelectedDate).format('LL')}`,
      };
      updateDriverInAllDriversArray(newDriverList);
      const response = await saveFleetScheduleData(params);

      setDriversSchedule([...driversSchedule]);
      computeTopAndHeight(driversSchedule, schedule);
      displayNotification(response.message);
    } catch (e) {
      setShowEditModal(true);
      console.error(e);
      displayNotification(e.message);
    }

    setIsUpdating(false);
  };

  const handleRightClick = (e) => {
    setXYPosition({
      x: e.clientX,
      y: e.clientY,
    });
    setShowRightClickMenu(!showRightClickMenu);
  };

  const validateTimeValues = (start, end) => {
    if (!start || !end) {
      displayNotification('Please input a valid time range.');

      return false;
    }
    if (!dateRangeValid(start, end)) {
      displayNotification('Please input a valid time range.');
      return false;
    }

    return true;
  };

  const handleSaveTimeBlock = async () => {
    if (validateTimeValues(timeBlockStart, timeBlockEnd)) {
      const start = setTimeWithDate(dispatchDate, timeBlockStart);
      const end = setTimeWithDate(dispatchDate, timeBlockEnd);
      setSpinner();
      try {
        const params = {
          driver_id: isTeam ? null : driver.driver_id,
          driver_team_id: isTeam ? driver.driver_team_id : null,
          shuttle_id: driver.shuttle_id,
          program_id: programId,
          start_time: dayjs(start).format('YYYY-MM-DDTHH:mm'), // (Format: "2023-03-28T16:00")
          end_time: dayjs(end).format('YYYY-MM-DDTHH:mm'), // (Format: "2023-03-28T16:00")
          notes: timeBlockNotes,
        };

        const resp = await saveTimeBlock(params);

        if (resp.status === 'success') {
          displayNotification('Successfully created time block.');

          const { trip } = resp.data;

          const tripIndex = vehicleTrips.findIndex(
            ({ shuttle_id, driver_id, driver_team_id }) =>
              shuttle_id === trip.shuttle?.shuttle_id &&
              driver_id === trip.driver_id &&
              driver_team_id === trip.driver_team_id
          );

          if (tripIndex > -1) {
            const { trips: newTrips } = vehicleTrips[tripIndex];
            newTrips.push(trip);
            vehicleTrips[tripIndex].trips = [...newTrips];
            combineAddedVehicle([...vehicleTrips]);
          }
          setTimeBlockNotes('');
          setShowTimeBlockModal(false);
        } else {
          displayNotification(resp.message);
        }
      } catch (e) {
        console.error(e);
      }

      unsetSpinner();
    }
  };

  const handleSaveLunchBreak = async () => {
    if (validateTimeValues(lunchBreakStart, lunchBreakEnd)) {
      setSpinner();
      const start = setTimeWithDate(dispatchDate, lunchBreakStart);
      const end = setTimeWithDate(dispatchDate, lunchBreakEnd);
      try {
        const params = {
          driver_id: isTeam ? null : driver.driver_id,
          driver_team_id: isTeam ? driver.driver_team_id : null,
          shuttle_id: driver.shuttle_id,
          program_id: programId,
          start_time: dayjs(start).format('YYYY-MM-DDTHH:mm'), // (Format: "2023-03-28T16:00")
          end_time: dayjs(end).format('YYYY-MM-DDTHH:mm'), // (Format: "2023-03-28T16:00")
        };

        const resp = await saveLunchBreak(params);

        if (resp.status === 'success') {
          displayNotification('Successfully created Lunch break.');

          const { trip } = resp.data;
          trip.drivershifts = ['exist'];
          const tripIndex = vehicleTrips.findIndex(
            ({ shuttle_id, driver_id, driver_team_id }) =>
              shuttle_id === trip.shuttle?.shuttle_id &&
              driver_id === trip.driver_id &&
              driver_team_id === trip.driver_team_id
          );

          if (tripIndex > -1) {
            const { trips: newTrips } = vehicleTrips[tripIndex];
            newTrips.push(trip);
            vehicleTrips[tripIndex].trips = [...newTrips];
            combineAddedVehicle([...vehicleTrips]);
          }
          setShowLunchBreakModal(false);
        } else {
          displayNotification(resp.message);
        }
      } catch (e) {
        console.error(e);
      }

      unsetSpinner();
    }
  };

  const handleUpdateShift = (value, selectedTimeDetails) => {
    if (value && value !== '') {
      if (isTeam) {
        handleDriverDateChange(
          new Date(value),
          selectedTimeDetails.keyIndex,
          selectedTimeDetails.period,
          selectedTimeDetails.driverSched
        );
      } else {
        handleDateChange(new Date(value), selectedTimeDetails.keyIndex, selectedTimeDetails.period);
      }
    }
  };

  const handleTimeBlock = () => {
    setShowTimeBlockModal(true);
    setShowRightClickMenu(false);
    setShowLunchBreakModal(false);
    setShowDriverAction(false);
  };

  const handleLunchBreak = () => {
    setShowDriverAction(false);
    setShowTimeBlockModal(false);
    setShowRightClickMenu(false);
    setShowLunchBreakModal(true);
  };

  const handleDriverAction = () => {
    setShowDriverAction(true);
    setShowTimeBlockModal(false);
    setShowRightClickMenu(false);
    setShowLunchBreakModal(false);
  };
  const handleMultiLoad = () => {
    // check how many bookings are checked
    // throw alert if less than two
    if (checkedBookings.length <= 1) {
      return displayNotification('Multiple bookings are required to process shared trips.');
    }

    if (checkedBookings.length > 3) {
      return displayNotification('3 bookings is the current allowed number for shared trips.');
    }

    setShowMultiLoad(true);
    setShowDriverAction(false);
    setShowTimeBlockModal(false);
    setShowRightClickMenu(false);
    setShowLunchBreakModal(false);
  };

  useEffect(() => {
    if (showTripMenu) {
      setXYPosition({
        x: xyPositionProps.x,
        y: xyPositionProps.y,
      });

      setShowRightClickMenu(true);
    }
    // eslint-disable-next-line
  }, [showTripMenu, xyPositionProps]);

  return (
    <>
      <DriverAction
        show={showDriverAction}
        cancel={() => setShowDriverAction(false)}
        close={() => setShowDriverAction(false)}
        dispatchDate={dispatchDate}
        tripId={null}
        driverId={driver.driver_id}
        driverTeamId={driver.driver_team_id}
        shuttleId={driver.shuttle_id}
        programId={programId}
        combineAddedVehicle={combineAddedVehicle}
        vehicleTrips={vehicleTrips}
        setSpinner={setSpinner}
        unsetSpinner={unsetSpinner}
      />

      <LunchBreakModal
        show={showLunchBreakModal}
        setShowModal={setShowLunchBreakModal}
        save={handleSaveLunchBreak}
        setStart={setLunchBreakStart}
        setEnd={setLunchBreakEnd}
        start={lunchBreakStart}
        end={lunchBreakEnd}
        dispatchDate={dispatchDate}
      />

      <TimeBlockModal
        showTimeBlockModal={showTimeBlockModal}
        setShowTimeBlockModal={setShowTimeBlockModal}
        handleSaveTimeBlock={handleSaveTimeBlock}
        setTimeBlockStart={setTimeBlockStart}
        setTimeBlockEnd={setTimeBlockEnd}
        timeBlockStart={timeBlockStart}
        timeBlockEnd={timeBlockEnd}
        setTimeBlockNotes={setTimeBlockNotes}
        timeBlockNotes={timeBlockNotes}
        dispatchDate={dispatchDate}
      />

      {showMultiLoad && (
        <MultiLoad
          updateBookingAfterSharedRide={updateBookingAfterSharedRide}
          dispatchDate={dispatchDate}
          programId={programId}
          setSpinner={setSpinner}
          unsetSpinner={unsetSpinner}
          driver={driver}
          bookings={checkedBookings}
          close={() => setShowMultiLoad(false)}
          vehicleTrips={vehicleTrips.filter(
            ({ shuttle_id, driver_id, driver_team_id }) =>
              shuttle_id === driver.shuttle_id &&
              driver_id === driver.driver_id &&
              driver_team_id === driver.driver_team_id
          )}
        />
      )}
      {
        // Right click menu
      }

      <TripMenu
        show={showRightClickMenu && !isNewScheduler}
        xyPosition={xyPosition}
        handleShiftSchedule={handleShiftSchedule}
        clickOption={{
          handleRightClick,
          shiftSchedule: handleShiftSchedule,
          timeBlock: handleTimeBlock,
          lunchBreak: handleLunchBreak,
          driverAction: handleDriverAction,
          multiLoad: handleMultiLoad,
        }}
      />
      {
        // End of right click menu
      }

      <Modal dialogClassName="auto-size" show={showEditModal} onHide={() => setShowEditModal(false)} centered>
        <ModalBody>
          <div
            key={showTimeBlockModal}
            className="card-box"
            style={{
              marginBottom: 0,
              padding: '40px 30px 50px 30px',
              maxWidth: '550px',
            }}
          >
            <div
              id="edit-modal"
              className=""
              onClick={(e) => {
                if (e.target.id === 'edit-modal') {
                  setShowEditModal(false);
                }
              }}
            >
              <div className="edit-modal-content timeblock">
                {isTeam ? (
                  // Team
                  <>
                    <div className="edit-modal-shift timeblock-title">Schedule or edit driver shift</div>
                    <div className="edit-modal-driver">
                      <div className="team-name">{driver.driver_team_name}</div>

                      <div className="drivers">
                        {schedule &&
                          schedule.length &&
                          schedule
                            .sort((a, b) => {
                              if (a.firstname === b.firstname) {
                                return a.lastname.localeCompare(b.lastname);
                              }
                              return a.firstname.localeCompare(b.firstname);
                            })
                            .map((driverSched) => {
                              // sort sched
                              let sortEd = [];
                              if (driverSched.schedule_data[0] && driverSched.schedule_data[0].time_data.length > 0) {
                                sortEd = driverSched.schedule_data[0].time_data.sort((a, b) => {
                                  return dayjs(`01/20/1988 ${a.start}`) - dayjs(`01/20/1988 ${b.start}`);
                                });
                              }
                              return (
                                <React.Fragment key={driverSched.driver_id + driverSched.driver_team_id}>
                                  <br />
                                  <div className="driver-name">
                                    {`${driverSched.firstname} ${driverSched.lastname}`}{' '}
                                    {driverSched.noSched || !driverSched.schedule_data.length ? '(Not scheduled)' : ''}
                                  </div>
                                  {sortEd.length > 0 ? (
                                    driverSched.schedule_data[0].time_data.map((sched, key) => {
                                      return (
                                        <TimeSelectComponent
                                          changeTimeValues={handleUpdateShift}
                                          key={Math.random()}
                                          startTime={sched.start_time}
                                          endTime={sched.end_time}
                                          shift={key}
                                          click={(e) =>
                                            e.target.scrollIntoView({
                                              behavior: 'smooth',
                                            })
                                          }
                                          convertTime={convertTime}
                                          driverSched={driverSched}
                                          dispatchDate={dispatchDate}
                                          sched={sched}
                                        />
                                      );
                                    })
                                  ) : (
                                    <TimeSelectComponent
                                      changeTimeValues={handleUpdateShift}
                                      key={Math.random()}
                                      startTime={null}
                                      endTime={null}
                                      shift={0}
                                      convertTime={convertTime}
                                      click={(e) =>
                                        e.target.scrollIntoView({
                                          behavior: 'smooth',
                                        })
                                      }
                                      driverSched={driverSched}
                                      dispatchDate={dispatchDate}
                                    />
                                  )}
                                </React.Fragment>
                              );
                            })}
                      </div>
                      <div className="update-buttons">
                        <button type="button" className="btn btn-secondary" onClick={handleTeamCancel}>
                          Cancel
                        </button>
                        <button type="button" className="btn btn-primary" onClick={updateTeamDriverSchedule}>
                          Save
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  // Single Driver
                  <div className="driver">
                    <div className="edit-modal-shift timeblock-title">Schedule or edit driver shift</div>
                    <div className="edit-modal-driver driver-name">
                      {driver.name} {!driversSchedule.length ? '(Not scheduled)' : ''}
                    </div>
                    {driversSchedule && driversSchedule.length > 0 ? (
                      driversSchedule?.map((sched, key) => {
                        return (
                          <TimeSelectComponent
                            key={Math.random()}
                            changeTimeValues={handleUpdateShift}
                            startTime={sched.start_time}
                            endTime={sched.end_time}
                            shift={key}
                            convertTime={convertTime}
                            dispatchDate={dispatchDate}
                            sched={sched}
                          />
                        );
                      })
                    ) : (
                      <TimeSelectComponent
                        changeTimeValues={handleUpdateShift}
                        startTime={null}
                        endTime={null}
                        shift={0}
                        convertTime={convertTime}
                        dispatchDate={dispatchDate}
                      />
                    )}
                    <div className="update-buttons">
                      <button type="button" className="btn btn-secondary" onClick={handleTeamCancel}>
                        Cancel
                      </button>
                      <button type="button" className="btn btn-primary" onClick={handleUpdateDriverSchedule}>
                        Save
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {
        // END OF EDIT MODAL
      }

      {!isUpdating && (
        <div
          id="driver-schedule-div"
          ref={thisDiv}
          onDragOver={(e) => handleOnDragOver(e)}
          onDragLeave={(e) => handleDragLeave(e)}
          onClick={handleClick}
          onContextMenu={(e) => {
            if (e.target.id === 'driver-schedule-div' || e.target.id === 'right-click-menu-container') {
              e.preventDefault();
              handleRightClick(e);
            }
          }}
          onMouseOut={handleMouseLeave}
          onBlur={handleMouseLeave}
          className={`driver-working-hours ${isMinimized ? 'minimized' : ''}`}
          style={{
            top: `${containerSettings.top}px`,
            height: `${containerSettings.height}px`,
          }}
        >
          <button type="button" className="minimize-btn" onClick={handleMinimized}>
            {isMinimized ? <i className="fa fa-chevron-down" /> : <i className="fa fa-chevron-up" />}
          </button>
          <button type="button" className="setting-btn" onClick={handleRightClick}>
            <i className="fa fa fa-gear" />
          </button>
          {!isTeam && (
            <>
              {schedule && schedule.schedule_data[0] ? (
                <div>
                  <span>{`Total hours: ${convertNumToTime(schedule.schedule_data[0].program_total_hours)}`}</span>

                  {driversSchedule &&
                    driversSchedule.map((sched, key) => {
                      return (
                        <div key={key}>
                          {`${composeTime12Format(sched.start_time)}-${composeTime12Format(sched.end_time)}`}
                        </div>
                      );
                    })}
                  <div> {isNaN(feeTotal) ? `N/A` : `$ ${feeTotal}`}</div>
                </div>
              ) : (
                'Driver schedule not set.'
              )}
            </>
          )}

          {isTeam && (
            <>
              {teamSchedule && teamSchedule.start && hours !== 0 ? (
                <div>
                  <span>{`Total hours: ${convertNumToTime(hours)}`}</span>

                  <div>{`${composeTime12Format(teamSchedule.start)}-${composeTime12Format(teamSchedule.end)}`}</div>

                  <div> {isNaN(feeTotal) ? `N/A` : `$ ${feeTotal}`}</div>
                </div>
              ) : (
                'Drivers schedule not set.'
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

const TimeSelectComponent = ({
  shift,
  startTime,
  endTime,
  changeTimeValues,
  driverSched = null,
  dispatchDate,
  sched,
}) => {
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');

  const saveStartTime = (value) => {
    try {
      const newVal = setDBTime(value, dispatchDate);
      changeTimeValues(newVal, {
        keyIndex: shift,
        period: 'start',
        values: {
          startTime: value,
          endTime: end,
        },
        driverSched,
      });
    } catch (e) {
      console.error(e);
    }
    setStart(value);
  };

  const saveEndTime = (value) => {
    try {
      const newVal = setDBTime(value, dispatchDate);
      changeTimeValues(newVal, {
        keyIndex: shift,
        period: 'end',
        values: {
          startTime: start,
          endTime: value,
        },
        driverSched,
      });
    } catch (e) {
      console.error(e);
    }
    setEnd(value);
  };

  const handleChangeStartTime = (val) => {
    // if (val && end) {
    //   const originalTime = {
    //     start: setDBTime(formatAMPM(convertTime(sched.start_time)), dispatchDate),
    //     end: setDBTime(formatAMPM(convertTime(sched.end_time)), dispatchDate),
    //   };

    //   const newEndTime = adjustTimeRange(val, end, 'start', dispatchDate, originalTime);

    //   if (newEndTime && newEndTime !== 'reset') {
    //     setTimeout(() => {
    //       saveEndTime(newEndTime); // hh:mm AM/PM
    //     }, 200);
    //   }
    // }

    saveStartTime(val);

    // eslint-disable-next-line
  };

  const handleChangeEndTime = (val) => {
    // if (start && val) {
    //   const newStartTime = adjustTimeRange(start, val, 'end', dispatchDate);
    //   if (newStartTime && newStartTime !== 'reset') {
    //     saveStartTime(newStartTime); // hh:mm AM/PM
    //   } else if (newStartTime === 'reset') {
    //     saveStartTime('12:00 AM');
    //     saveEndTime(`1:00 AM`); // hh:mm AM/PM

    //     return;
    //   }
    // }
    saveEndTime(val);
    // eslint-disable-next-line
  };

  useEffect(() => {
    if (startTime && startTime !== '') {
      setStart(formatAMPM(convertTime(startTime)));
    }

    if (endTime && endTime !== '') {
      setEnd(formatAMPM(convertTime(endTime)));
    }
  }, [startTime, endTime]);

  return (
    <React.Fragment>
      <div className="edit-modal-schedules">
        <div className="portal">
          <TimeInput
            label="Start shift"
            subLabel="(enter a value or use the drop-down list)"
            value={start}
            icon="/resources/images/Time_Square.png"
            isEdit
            placeHolder=""
            readOnly={false}
            onChangeEvent={handleChangeStartTime}
          />
        </div>

        <div className="portal">
          <TimeInput
            label="End shift"
            subLabel="(enter a value or use the drop-down list)"
            value={end}
            icon="/resources/images/Time_Square.png"
            isEdit
            placeHolder=""
            readOnly={false}
            onChangeEvent={handleChangeEndTime}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DriversWorkingHours;
