import React from 'react';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import DuetModal2, { DuetFullButtons } from '../../../components/Modals/modal2';
import modalStyles from '../../../components/Modals/modal2.module.scss';
import styles from '../../../components/Modals/modal.module.scss';
import CustomTextArea from '../../../components/Elements/TextInput/CustomTextArea';
import PlacesAutoComplete from '../../../components/Elements/TextInput/PlacesAutoComplete';
import TimeInput from '../../../components/Elements/TextInput/TimeInput';
import driverActionStyles from './driver-action-modal.module.scss';

export default function DriverActionModal({
  show,
  onClickAddOrEdit,
  startTime,
  endTime,
  details,
  location,
  handleSaveStartTime,
  handleSaveEndTime,
  setDetails,
  setLocation,
  cancel,
  handleSave,
}) {
  return (
    <DuetModal2 show={show} onClickClose={cancel} classes={{ modalContent: driverActionStyles['modal-content'] }}>
      <DriverAction
        onClickAddOrEdit={onClickAddOrEdit}
        startTime={startTime}
        endTime={endTime}
        details={details}
        location={location}
        handleSaveStartTime={handleSaveStartTime}
        handleSaveEndTime={handleSaveEndTime}
        setDetails={setDetails}
        setLocation={setLocation}
        cancel={cancel}
        handleSave={handleSave}
      />
    </DuetModal2>
  );
}

const DriverAction = ({
  onClickAddOrEdit,
  startTime,
  endTime,
  details,
  location,
  handleSaveStartTime,
  handleSaveEndTime,
  setDetails,
  setLocation,
  cancel,
  handleSave,
}) => {
  return (
    <>
      <div className="edit-modal-content">
        <div className="driver-action-title">
          Add or edit a driver action <InfoOutlined className={driverActionStyles.icon} onClick={onClickAddOrEdit} />
        </div>
        <br />
        <div className="driver-action-title notes">
          <b>Note:</b> this is visible to driver.
        </div>
        <div className={styles.form__content}>
          <div className={driverActionStyles['time-inputs-container']}>
            <TimeInput
              label="Start time"
              subLabel="(enter a value or use the drop-down list)"
              value={startTime}
              icon="/resources/images/Time_Square.png"
              IconCustomClass={driverActionStyles['clock-icon']}
              isEdit
              placeHolder=""
              readOnly={false}
              onChangeEvent={(value) => handleSaveStartTime(value)}
            />
            <TimeInput
              label="End time"
              subLabel="(enter a value or use the drop-down list)"
              value={endTime}
              icon="/resources/images/Time_Square.png"
              IconCustomClass={driverActionStyles['clock-icon']}
              isEdit
              placeHolder=""
              readOnly={false}
              onChangeEvent={(value) => handleSaveEndTime(value)}
            />
          </div>
          <div style={{ marginBottom: '20px' }}>
            <CustomTextArea
              disablePaste={false}
              readOnly={false}
              label="Action detail (This will be displayed as a note in the Driver app.)"
              value={details}
              isEdit
              iconClick={() => {}}
              onChangeEvent={(e) => setDetails(e.target.value)}
            />
          </div>
          <div style={{ marginBottom: '20px' }}>
            <PlacesAutoComplete
              value={location}
              updateLocation={(loc) => {
                setLocation(loc);
              }}
              label="Location (if you don't have one, enter your office address)"
            />
          </div>
        </div>
      </div>
      <DuetFullButtons>
        <Button
          variant="secondary"
          className={classNames(modalStyles.button, modalStyles['first-button'], 'w-80')}
          onClick={cancel}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          className={classNames(modalStyles.button, modalStyles['second-button'], 'w-80')}
          onClick={handleSave}
        >
          Save
        </Button>
      </DuetFullButtons>
    </>
  );
};
